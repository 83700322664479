@import './vars';

.payments-container {
  width: 100%;
  padding: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: #fff;

  @media screen and (max-width: 600px) {
    width: 1200px;
  }

  .icons {
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      animation: scroll 5s linear infinite;

    }

    .icon {
      width: 26px;
      height: 26px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes scroll {
  to {
    translate: calc(-12 * 70px);
  }
}