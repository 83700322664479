@import "./vars";

.modal {
  height: 90vh;
  max-height: 600px;
  overflow: auto;
  z-index: 100;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 0;
    }

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form {
    width: 100%;

    .message {
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #5b5b5b;
      margin: 0 0 3px;

      &.error {
        color: #e43f40;
      }

      &.success {
        color: #4bb543;
      }
    }

    form {
      width: 100%;
    }

    input {
      width: 100%;
      background: #ffffff;
      border: 2px solid #cacaca;
      border-radius: 3px;
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #767676;
      padding: 12px 14px;
      margin-bottom: 12px;
    }

    .captcha {
      margin: 20px 0;
    }

    ul {
      padding: 0;
      margin: -9px 0 10px 5px;

      li {
        font-size: 9px;
        margin-bottom: 2px;
      }
    }

    .errorInput {
      input {
        border-color: #e43f40;
      }

      ul li {
        color: #e43f40;
      }
    }

    button {
      background: #e43f40;
      border-radius: 10px;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 21px;
      line-height: 29px;
      text-align: center;
      color: #ffffff;
      border: 0;
      outline: none;
      height: 53px;

      &.cancel {
        margin: 10px 0;
        background-color: $color-gray-primary;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    .note {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: justify;
      color: #767676;
    }

    .checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      input {
        width: 12px;
        height: 12px;
        margin: 0;
      }

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-left: 10px;
        color: #767676;
      }
    }

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-decoration-line: underline;
      margin: 10px auto 0;
      color: #e43f40;
      display: block;
    }
  }

  .change {
    padding: 50px 0 0;

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      align-items: center;
      text-align: center;
      color: #000000;
      width: 100%;
      margin: 0 auto;
    }

    a {
      font-weight: 600;
      font-size: 21px;
      line-height: 29px;
      display: block;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      margin: 8px auto 0;
      color: #e43f40;
    }
  }

  .demo {
    margin-top: 30px;

    & button {
      color: #e43f40;
      background: transparent;
      cursor: pointer;
      text-decoration: none;
      padding: 0;
      font-size: 17px;
    }
  }

  &--small {
    max-height: 300px;

    .header {
      margin-bottom: 50px;
    }
  }

  .links_signup {
    font-weight: 600;
    font-size: 21px;
    color: #e43f40;
    background: transparent;
  }
}